/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Button, Tabs } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';

import "./project.css";

import useScrollToTop from '../../utils/useScrollToTop';
import { post } from '../../utils/fetch';
import { PageContext, usePageActionHandler } from '../../components/Page';
import { actionTypes } from '../../actions';

import ProjectTopLeft from './ProjectTopLeft';
import AddNewCourse from './AddNewCourse';
// import AddNewSource from './AddNewSource';
// import ProjectSource from "./Source";
import ProjectCourse from "./Course";
import ProjectDetails from './ProjectDetails';
import TabLabel from '../../components/TabLabel';
import DropdownMenu from '../../components/DropdownMenu';

export const ProjectContext = createContext(null);

const Project = () => {
  useScrollToTop()
  const { setError, resetConfirmationState } = useContext(PageContext);
  const { addAction, removeAction } = usePageActionHandler();
  const history = useHistory();
  const { projectId, type, contentId } = useParams();
  const [project, setProject] = useState(null);

  useEffect(() => {
    getProject();
  }, [])

  useEffect(() => {
    resetConfirmationState();
  }, [type, contentId])

  const handleSetProject = ({ name, description }) => {
    const schema = {
      name,
      description
    }

    setProject(prev => ({
      ...prev,
      ...schema
    }))
  }

  const handleSetSources = (id, name) => {
    const schema = {
      id,
      name
    }

    setProject(prev => ({
      ...prev,
      sources: [
        ...(prev.sources || []),
        schema
      ]
    }))
  }

  const handleDeleteCourseSuccess = (courseId) => {
    const newCourses = [
      ...project.courses
    ]
    const courseIndex = newCourses.findIndex((item) => item.id === courseId);
    newCourses.splice(courseIndex, 1);
    setProject({
      ...project,
      courses: newCourses
    })
  }

  const handleDeleteSourceSuccess = (sourceId) => {
    const newSources = [
      ...project.sources
    ]
    const courseIndex = newSources.findIndex((item) => item.id === sourceId);
    newSources.splice(courseIndex, 1);
    setProject({
      ...project,
      sources: newSources
    })
  }

  const handleCreateCourseSuccess = (courseDetails) => {
    const newCourses = [
      {
        ...courseDetails
      },
      ...project.courses
    ]
    setProject({
      ...project,
      courses: newCourses
    })
  }

  const handleChangeContent = (key) => {
    if (key === 'new') {
      history.push(`/studio/project/${projectId}/${type}/${key}`);
    } else {
      history.push(`/project/${projectId}/${type}/${key}`);
    }
  }

  const getProject = async () => {
    const action = "getProject";
    addAction(action, actionTypes[action].fetchMessage)
    await post(actionTypes[action].api, {
      projectId,
    })
    .then((res) => {
      setProject(res.data);
    })
    .catch((err) => {
      setError(err)
    })
    removeAction(action)
  }
  
  const tabItems = getTabItems(
    type,
    project,
    getProject,
    handleSetProject,
    handleSetSources
  )

  return (
    <ProjectContext.Provider
      value={{
        handleDeleteCourseSuccess,
        handleCreateCourseSuccess,
        handleDeleteSourceSuccess
      }}
    >
      <div className='project-root'>
        <Tabs
          style={{ height: 'calc(100vh - 12px)' }}
          activeKey={contentId || "new"}
          destroyInactiveTabPane
          tabPosition={window.innerWidth > 800 ? 'left' : 'top'}
          tabBarStyle={{
            maxHeight: 'calc(100vh - 30px)',
            width: window.innerWidth > 800 ? '30%' : undefined,
            maxWidth: window.innerWidth > 800 ? 230 : undefined
          }}
          size="small"
          type="card"
          onChange={handleChangeContent}
          tabBarExtraContent={{
            left: window.innerWidth > 800 && <ProjectTopLeft project={project} />,
            right: (
              <div style={{ display: 'flex' }}>
                <Button
                  style={{ display: 'flex', padding: '0 20px', height: 'fit-content' }}
                  type='ghost'
                  onClick={() => {
                    history.push("/studio/projects")
                  }}
                >
                  Back to projects
                </Button>
              </div>
            )
          }}
          items={tabItems}
        />
      </div>
    </ProjectContext.Provider>
  );
}

const getTabItems = (type, project, getProject, handleSetProject, handleSetSources) => {
  if (type === 'details') {
    return [
      {
        label: "Project details",
        key: "details",
        children: (
          <ProjectDetails
            project={project}
            projectCourses={project?.courses}
            getProject={getProject}
            handleSetProject={handleSetProject}
          />
        )
      }
    ];
  }

  const items = type === 'course' ? project?.courses : project?.sources;
  const Component = ProjectCourse;
  const AddNewComponent = AddNewCourse;

  return [
    {
      label: "Add new",
      key: "new",
      children: (
        <AddNewComponent
          getProject={getProject}
          handleSetSources={handleSetSources}
        />
      )
    },
    ...(items || []).map((item) => ({
      label: <TabLabel label={item.name} />,
      key: item.id.toString(),
      children: <Component data={item} />
    }))
  ];
};

export default Project;
