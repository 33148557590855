import { Button, Card, Input, Radio, Tag, message } from "antd";
import CommonPage from "../../components/CommonPage"
import { Link, useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useContext, useState } from "react";

import { PageContext, usePageActionHandler } from '../../components/Page';
import { AppContext } from "../../App";
import { useRef } from "react";
import { post } from "../../utils/fetch";
import { abbreviateNumber } from "../../utils/utils";
import { actionTypes } from "../../actions";

// const How = () => (
//   <div>
//     <span>
//       Subscribing to ChatGPT Plus does not automatically provide you with an API key. You will still need to register on the OpenAI website (not ChatGPT). Follow these steps:
//     </span>
//     <ol>
//       <li>
//         Navigate to the official <a href="https://openai.com/" target="_blank" rel="noreferrer">OpenAI</a> website and create an account.
//       </li>
//       <li>
//         Once registered, visit this <a href="https://platform.openai.com/account/api-keys" target="_blank" rel="noreferrer">link</a> to generate your API key.
//       </li>
//       <li>
//         Copy the generated API key and paste it below.
//       </li>
//       <li>
//         If you still encounter errors such as "Too many requests", try to <a target="_blank" rel="noreferrer" href="https://platform.openai.com/account/billing/overview">upgrade</a> your OpenAI account by entering your billing information.
//       </li>
//       <li>
//         To have an access to GPT-4 model, read this <a target="_blank" rel="noreferrer" href="https://help.openai.com/en/articles/7102672-how-can-i-access-gpt-4">post</a>.
//       </li>
//     </ol>
//   </div>
// )

const Account = () => {
  let openAiApiKey = localStorage.getItem('openAiApiKeyV2');
  let currentAiModel = localStorage.getItem('aiModel');
  const apiKeyInputRef = useRef(null);
  const history = useHistory()
  const location = useLocation();
  const [messageApi, contextHolder] = message.useMessage();
  const searchParams = new URLSearchParams(location.search);
  const redemption = searchParams.get('redemption');
  const { appState, appActions } = useContext(AppContext);
  const { setError, isActionInProgress } = useContext(PageContext);
  const { addAction, removeAction } = usePageActionHandler();
  const [aiModel, setAiModel] = useState(currentAiModel || "gpt-3.5-turbo-16k");
  const [redemptionCode, setRedemptionCode] = useState(null);

  const handleSetApiKey = async () => {
    const api = `/toggle-use-token`;
    addAction(api)
    await post(api, {
      apiKey: apiKeyInputRef.current.input.value,
      aiModel
    })
      .then((res) => {
        if (apiKeyInputRef.current.input.value) {
          localStorage.setItem('openAiApiKeyV2', apiKeyInputRef.current.input.value);
          localStorage.setItem('aiModel', aiModel);
        } else {
          localStorage.removeItem('openAiApiKeyV2');
          localStorage.removeItem('aiModel');
        }
        messageApi.open({
          type: 'success',
          content: 'Success',
          duration: 3,
        });
      })
      .catch((err) => {
        setError(err)
      })
      removeAction(api)
  }

  const claimRedemptionCode = async () => {
    const action = `claimRedemptionCode`;
    addAction(action);
    await post(actionTypes[action].api, {
      code: redemptionCode
    })
      .then(async (res) => {
        await appActions.getUser();
        messageApi.open({
          type: 'success',
          content: 'Success',
          duration: 3,
        });
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        removeAction(action)
      })
  }

  return (
    <CommonPage title="Account">
      {contextHolder}
      <Card>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <div style={{ display: 'flex', gap: 20 }}>
            <h3>Profile</h3>
          </div>
          <div style={{ display: 'flex', gap: 5 }}>
            <span>Id:</span>
            <span>{appState?.user?.id}</span>
          </div>
          <div style={{ display: 'flex', gap: 5 }}>
            <span>Registered email:</span>
            <span>{appState?.user?.email}</span>
          </div>
        </div>
      </Card>
      <Card>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <div style={{ display: 'flex', gap: 20 }}>
            <h3>Tokens</h3>
            <div style={{ display: 'flex', gap: 5 }}>
              <Link to="/activity/token">
                <Button size="small">Token usage activity</Button>
              </Link>
              <Link to="/pricing/token">
                <Button size="small">Buy tokens</Button>
              </Link>
            </div>
          </div>
          <div style={{ display: 'flex', gap: 5 }}>
            <span>Token balance:</span>
            <span>{abbreviateNumber(appState?.tokenPlan?.no_of_token_left)}</span>
          </div>
        </div>
      </Card>
      <Card>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <div style={{ display: 'flex', gap: 20, alignItems: 'baseline' }}>
            <h3>API key</h3>
            <div style={{ display: 'flex', gap: 5 }}>
              {
                openAiApiKey && appState?.selfApiPlan?.plan_id !== "self_api_key_unlimited" && <Tag color="red">Inactive</Tag>
              }
              {
                appState?.selfApiPlan?.plan_id === "self_api_key_unlimited" && <Tag color="blue">{appState?.selfApiPlan.name}</Tag>
              }
              {
                openAiApiKey && appState?.selfApiPlan?.plan_id !== "self_api_key_unlimited" && (
                  <Button size="small" onClick={() => { history.push('/pricing/self-api-key') }}>Subscribe now</Button>
                )
              }
              {
                appState?.selfApiPlan?.plan_id === "self_api_key_unlimited" &&
                <a href="https://billing.stripe.com/p/login/28o8Amdk38vu7D29AA" target="_blank" rel="noreferrer">
                  <Button size="small">Manage subscription</Button>
                </a>
              }
            </div>
          </div>
          <div style={{ display: 'flex', gap: 10, flexDirection: 'column' }}>
            {
              openAiApiKey && <div>Remove the API key to start using platform tokens.</div>
            }
            {
              openAiApiKey && appState?.selfApiPlan?.plan_id === "self_api_key_unlimited" && (
                <Radio.Group onChange={(val) => { setAiModel(val.target.value) }} value={aiModel}>
                  <Radio value="gpt-3.5-turbo-16k">GPT 3.5 Turbo 16K</Radio>
                  <Radio value="gpt-4">GPT 4</Radio>
                  <Radio value="gpt-4-turbo-preview">GPT 4 Turbo</Radio>
                  <Radio value="gpt-4o">GPT 4o</Radio>
                </Radio.Group>
              )
            }
            <Input
              defaultValue={openAiApiKey}
              ref={apiKeyInputRef}
              style={{ maxWidth: 600 }}
            />
            <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
              <Button
                disabled={isActionInProgress('/toggle-use-token')}
                loading={isActionInProgress('/toggle-use-token')}
                style={{ alignSelf: 'flex-start' }}
                onClick={handleSetApiKey}
              >
                Save
              </Button>
              <Link to="/pricing/self-api-key">
                Read more
              </Link>
            </div>
          </div>
        </div>
      </Card>
      {
        redemption === 'appsumo' &&
        (
          <Card>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
              <div style={{ display: 'flex', gap: 20, alignItems: 'baseline' }}>
                <h3>Redemption code</h3>
              </div>
              <div style={{ display: 'flex', gap: 10, flexDirection: 'column' }}>
                <Input
                  value={redemptionCode}
                  onChange={(val) => { setRedemptionCode(val.target.value) }}
                  style={{ maxWidth: 600 }}
                  />
                <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                  <Button
                    disabled={isActionInProgress('/submitRedemptionCode')}
                    loading={isActionInProgress('/submitRedemptionCode')}
                    style={{ alignSelf: 'flex-start' }}
                    onClick={claimRedemptionCode}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        )
      }
    </CommonPage>
  )
}

export default Account;
