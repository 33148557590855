import React from 'react';
import { useQuill } from 'react-quilljs';
import { ImageFormats } from '@xeger/quill-image-formats';

import './contentEditor.css';

const ContentViewer = ({ delta={}, html='' }) => {
  const { quill, quillRef, Quill } = useQuill({
    theme: "bubble",
    formats: [
      'background', 'bold', 'color', 'code', 'italic', 'link', 'size', 
      'strike', 'script', 'underline', 'blockquote', 'header', 'indent', 'list', 
      'align', 'direction', 'code-block', 'formula', 'image', 'height', 'width', 'float'
    ],
    modules: {
      imageFormats: {},
    }
  });

  if (Quill && !quill) {
    const block = Quill.import('blots/block');
    block.tagName = 'div';
    Quill.register(block);
    Quill.register('modules/imageFormats', ImageFormats);
  }

  React.useEffect(() => {
    if (quill) {
      if (!delta) {
        quill.clipboard.dangerouslyPasteHTML(html)
      } else {
        quill.setContents(delta)
      }
      quill.enable(false); 
    }
  }, [quill, html, delta]);

  return (
    <div className='content-editor'>
      <div ref={quillRef} />
    </div>
  );
};

export default ContentViewer;