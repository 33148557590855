import { Button, InputNumber, Slider } from 'antd';
import { useContext, useState } from 'react';
import { PageContext } from '../../components/Page';

const minValue = process.env.REACT_APP_ENV === 'production'
  ? 100000
  : 10000

const PriceSlider = ({ decimalInputValue, setDecimalInputValue, handleGenerateBuyTokenPaymentLink }) => {
  const { isActionInProgress } = useContext(PageContext);
  const [inputValue, setInputValue] = useState(100000);

  const onChange = (newValue) => {
    setInputValue(newValue);
    setDecimalInputValue(newValue / 10000)
  };

  const onChangeDecimalInput = (value) => {
    if (isNaN(value)) {
      return;
    }
    setDecimalInputValue(value);
    setInputValue(value * 10000);
  };
  
  return (
    <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
      {
        window.innerWidth > 600 &&
        <div style={{ width: '100%' }}>
          <Slider
            min={minValue}
            max={2000000}
            step={10000}
            onChange={onChange}
            value={typeof inputValue === 'number' ? inputValue : 0}
            tooltip={{
              formatter: value => {
                if (value >= 1000000) {
                    return `${(value / 1000000).toFixed(1)}m`;
                }
                return `${(value / 1000)}k`;
              },
              parser: value => {
                if (value.includes("m")) {
                    return value.replace("m", "") * 1000000;
                }
                return value.replace("k", "") * 1000;
              }
            }}
          />
        </div>
      }
      <div style={{ display: 'flex', gap: 10 }}>
        <div>
          <p style={{ textAlign: 'center' }}>Token</p>
          <InputNumber
            min={minValue}
            max={2000000}
            step={10000}
            formatter={value => {
                if (value >= 1000000) {
                    return `${(value / 1000000).toFixed(1)}m`;
                }
                return `${(value / 1000)}k`;
            }}
            parser={value => {
                if (value.includes("m")) {
                    return value.replace("m", "") * 1000000;
                }
                return value.replace("k", "") * 1000;
            }}
            value={inputValue}
            onChange={onChange}
          />
        </div>
        <div style={{ display: 'flex', gap: 10, alignItems: 'end' }}>
          <div>
            <p style={{ textAlign: 'center' }}>Price</p>
            <InputNumber
              style={{ width: '100%' }}
              prefix="$"
              min={minValue / 10000}
              max={200}
              step={1.00}
              value={decimalInputValue}
              onChange={onChangeDecimalInput}
            />
          </div>
          <Button
            disabled={isActionInProgress('createTokenPaymentLink')}
            loading={isActionInProgress('createTokenPaymentLink')}
            onClick={handleGenerateBuyTokenPaymentLink}
          >
            Pay
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PriceSlider;