import { Alert, Button, Input } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import { DoubleLeftOutlined, DoubleRightOutlined, QuestionOutlined, SendOutlined } from '@ant-design/icons';
import { Link, useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";

import CodeBlock from "../../components/CodeBlock";
import Markdown from "../../components/Markdown";
import { PageContext, usePageActionHandler } from "../../components/Page";
import { post } from "../../utils/fetch";
import { actionTypes } from "../../actions";
import Lessons from "./Lessons";
import { AppContext } from "../../App";
import TooltipButton from "../../components/TooltipButton";
import OverviewEditor from "../../pages/template/OverviewEditor";
import Show from "../../components/Show";

const { TextArea } = Input;

const intro = `
Hello, I'm your dedicated assistant. My role here is to gather essential details from you, which will be utilized to create a tailor-made course.

My mission is to have a comprehensive understanding of what you're looking for, so I can present you with a highly impactful and relevant course in your area of interest.

Let's get started. What is your preferred language of instruction for the course? 
`

const Home = () => {
  const chatRef = useRef();
  const chatEndRef = useRef();
  const history = useHistory();
  const params = useParams();
  const { appState } = useContext(AppContext);
  const { setError, withConfirmation, isActionInProgress } = useContext(PageContext);
  const { addAction, removeAction } = usePageActionHandler();
  const [aiResponse, setAiResponse] = useState(null);
  const [courseOverviewConversation, setCourseOverviewConversation] = useState(
    JSON.parse(localStorage.getItem("courseOverviewConversation"))
    || []
  )
  const [courseOverview, setCourseOverview] = useState(null);
  const sessionId = params.courseId || localStorage.getItem('sessionId');
  const [course, setCourse] = useState(null);
  const [extendLesson, setExtendLesson] = useState(false)
  const [chatInputKey, setChatInputKey] = useState(Date.now())
  const [tokenLeft, setTokenLeft] = useState(null);
  const [processingMessage, setProcessingMessage] = useState(null);
  const [hideUpgradeNotes, setHideUpgradeNotes] = useState(localStorage.getItem("hideUpgradeNotes"));
  const openAiApiKey = localStorage.getItem('openAiApiKeyV2');
  const currentPlan = appState?.[openAiApiKey ? "selfApiPlan" : "tokenPlan"];

  useEffect(() => {
    if (course?.overview) {
      scrollToBottom();
    }
  }, [course?.overview])

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKETIO);
    if (appState?.user) {
      socket.emit('subscribeToStatusUpdate', appState?.user?.id);
      socket.on('statusUpdate', (socketData) => {
        const { data } = socketData;
        if (data.action === 'tokenUpdate') {
          setTokenLeft(data.data.tokenLeft)
        }
      });
    }

    return () => {
      socket.disconnect();
    };
  }, [appState?.user])

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKETIO);
    scrollToBottom();

    if (localStorage.getItem("accessToken") && (!params.projectId || !sessionId)) {
      history.push("/studio/projects")
      return;
    }

    if (!sessionId) {
      history.push("/home")
      return;
    }

    if (params.courseId) {
      getCourse();
    }

    if(!params.projectId && localStorage.getItem("projectId")) {
      history.push(`/project/${localStorage.getItem("projectId")}}`)
      return;
    }

    if(!params.courseId && localStorage.getItem("courseId")) {
      history.push(`/project/${localStorage.getItem("projectId")}/course/${localStorage.getItem("courseId")}`)
      return;
    }

    if (sessionId) {
      handleSetConversation(courseOverviewConversation)
      socket.emit('subscribeToStatusUpdate', sessionId);
      socket.on('statusUpdate', (socketData) => {
        const { data } = socketData;
        if (data.error) {
          getCourse()
          if (data.error.code === 'not_enough_token') {
            setError("Low token balance. Please top up to continue app access.");
            return;
          }
          setError(data.error)
          return;
        }

        if (data.action === 'streamingCourseOverviewChatResponse') {
          setAiResponse(data.data.reply)
          scrollToBottom();
        }

        if (data.action === 'completeCourseOverviewChatResponse') {
          setAiResponse(null);
          const latestConversation = [
            ...(JSON.parse(localStorage.getItem("courseOverviewConversation"))),
            {
              role: "assistant",
              content: data.data.reply
            }
          ]
          handleSetConversation(latestConversation)
          scrollToBottom();
          removeAction('chat');
        }

        if (data.action === 'streamingGenerateOverview') {
          setCourseOverview(data.data.reply)
          scrollToBottom();
        }

        if (data.action === 'completeGenerateOverview') {
          setAiResponse(null);
          setCourseOverview(data.data.reply)
          scrollToBottom();
          getCourse()
          removeAction('generateCourseOverview');
        }

        if (data.action === 'processingGenerateLessonKeypoints') {
          console.log('processing');
          setProcessingMessage("[2/2] Generating key points for each modules. Please wait...")
        }

        if (data.action === 'generateModules') {
          getCourse()
          setProcessingMessage(null)
        }

        if (data.action === 'generateSubmodules') {
          getCourse()
        }

        if (data.action === 'submoduleContentGenerationUpdate') {
          getCourse()
        }
      });
    }

    return () => {
      socket.disconnect();
    };
  }, [sessionId, params.projectId, params.courseId])

  const handleSetConversation = (conversation) => {
    localStorage.setItem("courseOverviewConversation", JSON.stringify(conversation))
    setCourseOverviewConversation(conversation)
  }

  const getCourse = async () => {
    const action = `getCourseDetails`;
    addAction(action);
    await post(actionTypes[action].api, {
      courseId: params.courseId,
    })
      .then((res) => {
        setCourse(res.data.course);
        setCourseOverviewConversation(
          res.data.course.overview
          ? [{role: 'assistant', content: res.data.course.overview }]
          : res.data.course.overview_chat)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        removeAction(action)
        scrollToBottom();
      })
  }

  const generateModules = () => withConfirmation("generateModules",
    async (action) => {
      addAction(action);
      setProcessingMessage("[1/2] Generating modules. This will take some time. Please wait...")
      if (window.innerWidth < 1000) {
        setExtendLesson(true);
      }
      await post(actionTypes[action].api, {
        courseId: params.courseId,
        apiKey: localStorage.getItem('openAiApiKeyV2'),
        aiModel: localStorage.getItem("aiModel")
      })
        .then(async (res) => {
          await getCourse();
        })
        .catch((err) => {
          setError(err)
        })
        .finally(() => {
          removeAction(action)
        })
      },
    !!(course?.course_module.length)
  )

  const generateHandsOnModules = () => withConfirmation("generateHandsOnModules",
  async (action) => {
    addAction(action);
    if (window.innerWidth < 1000) {
      setExtendLesson(true);
    }
    await post(actionTypes[action].api, {
      courseId: params.courseId,
      apiKey: localStorage.getItem('openAiApiKeyV2'),
      aiModel: localStorage.getItem("aiModel")
    })
      .then(async (res) => {
        await getCourse();
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        removeAction(action)
      })
    },
  !!(course?.course_module.length)
)

  const handleReply = async () => {
    const action = `chat`;
    addAction(action);
    const reply = [
      ...courseOverviewConversation,
      {
        role: "user",
        content: chatRef.current?.resizableTextArea?.textArea?.value
      }
    ];
    handleSetConversation(reply)
    await post(actionTypes[action].api, {
      sessionId,
      reply,
      handsOn: !localStorage.getItem('accessToken') && localStorage.getItem('handsOn') === "true",
      apiKey: localStorage.getItem('openAiApiKeyV2'),
      aiModel: localStorage.getItem("aiModel")
    })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setChatInputKey(Date.now());
        scrollToBottom();
      })
  }

  const endSession = async () => {
    const action = `generateCourseOverview`;
    addAction(action);
    const reply = [
      ...courseOverviewConversation,
      {
        role: "user",
        content: "End session"
      }
    ];
    handleSetConversation(reply)
    await post(actionTypes[action].api, {
      courseId: params.courseId,
      projectId: params.projectId,
      courseTitle: !params.courseId && localStorage.getItem('courseTitle'),
      handsOn: !params.courseId && localStorage.getItem('handsOn') === "true",
      conversation: reply,
      apiKey: localStorage.getItem('openAiApiKeyV2'),
      aiModel: localStorage.getItem("aiModel")
    })
      .then((res) => {
        localStorage.setItem("courseId", res.data.id)
        history.push(`/project/${params.projectId}/course/${res.data.id}`)
      })
      .catch((err) => {
        setError(err)
        removeAction(action)
      })
      .finally(() => {
        scrollToBottom();
      })
  }

  const generateSubmodules = (module) => withConfirmation("generateSubmodules",
    async (action) => {
      addAction(action);
      await post(actionTypes[action].api, {
        courseId: params.courseId,
        moduleId: module.id,
        apiKey: localStorage.getItem('openAiApiKeyV2'),
        aiModel: localStorage.getItem("aiModel")
      })
        .then(async (res) => {
          await getCourse()
        })
        .catch((err) => {
          setError(err)
        })
        .finally(() => {
          removeAction(action)
        })
    },
    !!(module.keypoints.length > 0)
  )

  const generateContents = (moduleId, submodule) => withConfirmation("generateContent",
    async (action) => {
      addAction(action);
      await post(actionTypes[action].api, {
        courseId: params.courseId,
        moduleId,
        submoduleId: submodule?.id || undefined,
        apiKey: localStorage.getItem('openAiApiKeyV2'),
        aiModel: localStorage.getItem("aiModel")
      })
        .then(async (res) => {
          await getCourse()
        })
        .catch((err) => {
          setError(err)
        })
        .finally(() => {
          removeAction(action)
        })
    },
    !!(submodule?.status_id === 'ready')
  )

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView()
  }

  const handleSortModules = async (newModules, deletedModule) => {
    const action = "sortCourseModules";
    addAction(action);
    await post(actionTypes[action].api, {
      courseId: course.id,
      sortedModules: newModules.map((item) => item.id),
      deletedModuleId: deletedModule?.id || undefined
    })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      })
  }

  const handleSortSubmodules = async (moduleIndex, newSubmodules, deletedSubmodule) => {
    const action = "sortCourseSubmodules";
    addAction(action);
    await post(actionTypes[action].api, {
      moduleId: course.course_module[moduleIndex].id,
      courseId: course.id,
      sortedSubmodules: newSubmodules.map((item) => item.id),
      deletedSubmoduleId: deletedSubmodule?.id || undefined
    })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      })
  }

  const deleteCourse = () => withConfirmation("deleteCourse",
  async (action) => {
    addAction(action)
    await post(actionTypes[action].api, {
      courseId: course.id,
    })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        removeAction(action);
        history.push(`/studio/project/${params.projectId}/course/new`)
      })
  })

  const handleUpdateOverview = async (delta, text) => {
    const action = `updateCourseOverview`;
    addAction(action)
    await post(actionTypes[action].api, {
      courseId: course.id,
      delta,
      text
    })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        removeAction(action)
      })
  }

  return (
      <div style={{ display: 'flex', padding: 10, gap: 10 }}>
        <Show style={{ width: window.innerWidth > 1000 ? 'calc(100vw - 550px)' : '100vw', }} show={(params.courseId && course) || !params.courseId} rows={3}>
        {
          (courseOverview || course?.overview) ?
          (
            <div style={{ width: window.innerWidth > 1000 ? 'calc(100vw - 550px)' : '100vw', display: extendLesson ? 'none' : 'flex', flexDirection: 'column', justifyContent: 'space-between', height: 'calc(100vh - 40px)' }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 10, overflow: 'auto', paddingRight: course?.overview ? 10 : 0 }}>
                <div style={{ padding: 20, width: '100%', maxWidth: 800, alignSelf: "center" }}>
                  <OverviewEditor
                    markdown={courseOverview}
                    delta={course?.overview_delta}
                    html={course?.overview}
                    onSave={handleUpdateOverview}
                    onGenerateLesson={generateModules}
                    extendLesson={extendLesson}
                    setExtendLesson={setExtendLesson}
                  />
                </div>
              </div>
            </div>
          ) : 
          (
            <div style={{ width: window.innerWidth > 1000 ? 'calc(100vw - 550px)' : '100vw', display: extendLesson ? 'none' : 'flex', flexDirection: 'column', justifyContent: 'space-between', height: 'calc(100vh - 40px)' }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 10, overflow: 'auto', height: 'calc(100vh - 186px)', paddingRight: course?.overview ? 10 : 0 }}>
                {
                  !course?.overview && !course?.learning_goals && (
                    <div style={{ borderRadius: "20px 0 0 20px", backgroundColor: "#e8feff", padding: "30px 30px 15px 30px", width: '80%', alignSelf: "end" }}>
                      <Markdown className="chat-markdown" components={{ code: CodeBlock }}>
                        {intro}
                      </Markdown>
                    </div>
                  )
                }
                {
                  courseOverviewConversation?.map((item) => {
                    return (
                      <div key={item.id} style={{ borderRadius: item.role === 'user' ? "0px 20px 20px 0px" : "20px 0 0 20px", backgroundColor: item.role === 'user' ? "#fff1a5" : "#e8feff", padding: "30px 30px 15px 30px", width: '80%', alignSelf: item.role === 'user' ? undefined : 'end' }}>
                        <Markdown className="chat-markdown" components={{ code: CodeBlock }}>
                          {item.content}
                        </Markdown>
                      </div>
                    )
                  })
                }
                {
                  aiResponse && (
                    <div style={{ borderRadius: 20, backgroundColor: "#e8feff", padding: "30px 30px 15px 30px", width: '80%', alignSelf: "end" }}>
                      <Markdown className="chat-markdown" components={{ code: CodeBlock }}>
                        {aiResponse}
                      </Markdown>
                    </div>
                  )
                }
                <div ref={chatEndRef} />
              </div>
              {
                ((!appState?.user?.id || (currentPlan?.is_trial && currentPlan?.no_of_token_left === 30000)) && !hideUpgradeNotes)
                  ? (
                    <Alert
                      message={<b>Welcome!</b>}
                      description={
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                          <div>
                            You've received an access to the basic AI model. <b>Enhance your experience</b> by upgrading to the advanced AI model with the purchase of extra tokens
                          </div>
                          <div style={{ display: 'flex', gap: 10, justifyContent: 'end' }}>
                            <Button
                              style={{ alignSelf: 'end' }}
                              onClick={() => {
                                localStorage.setItem('hideUpgradeNotes', true);
                                setHideUpgradeNotes(true);
                              }}
                            >
                              Use basic AI
                            </Button>
                            <Link to="/pricing/token">
                              <Button
                                style={{ alignSelf: 'end' }}
                              >
                                Upgrade
                              </Button>
                            </Link>
                          </div>
                        </div>
                      }
                      type="info"
                    />
                  ) : (
                    <div style={{ position:' relative' }}>
                    <TextArea
                      ref={chatRef}
                      key={chatInputKey}
                      rows={4}
                      placeholder={`Enter your answer and click the 'Submit' button. When you are done, click the 'End Session' button to proceed with generating the lessons.`}
                      style={{ paddingTop: 20 }}
                      disabled={course?.overview}
                    />
                    <div style={{ position: 'absolute', zIndex: 99, right: 16, top: -16, display: 'flex', gap: 5 }}>
                      {
                        !course?.overview && !course?.learning_goals && (
                          <TooltipButton
                            title="Submit"
                            style={{ backgroundColor: 'white', opacity: 1 }}
                            type="default"
                            size="medium"
                            disabled={isActionInProgress()}
                            onClick={handleReply}
                            icon={SendOutlined}
                          />
                        )
                      }
                      <Button
                        type={course?.overview ? "primary" : "default"}
                        onClick={async () => {
                          if (course?.overview) {
                            if (course?.hands_on) {
                              await generateHandsOnModules()
                            } else {
                              await generateModules()
                            }
                          } else if (localStorage.getItem("accessToken")) {
                            await endSession()
                          } else {
                            history.push("/signin")
                          }
                        }}
                        style={{ backgroundColor: course?.overview ? undefined : 'white', opacity: 1 }}
                        disabled={isActionInProgress() || courseOverviewConversation?.length === 0}
                      >
                        {
                          course?.overview
                            ? 'Generate lessons'
                            : 'End session'
                        }
                      </Button>
                      <TooltipButton
                        title={(
                          <ol>
                            <li>Begin a conversation with the chatbot, which will start asking about your course preferences.</li>
                            <li>After you've completed the chat, hit the 'End session' button. Now, the chatbot gets to work on designing your course overview.</li>
                            <li>Next, click on the 'Generate table of contents' button on top of the course overview. Your lessons will start to take shape.</li>
                            <li>Check 'Table of Contents' sidebar & wait until the lessons are generated, then hit the 'Generate' button.</li>
                            <li>A 'View' button will show up. Click on it and your customized course content is ready for review.</li>
                          </ol>
                        )}
                        type="default"
                        size="medium"
                        icon={QuestionOutlined}
                        style={{ width: 22 }}
                      />
                      <Button
                        onClick={() => { setExtendLesson(!extendLesson) }}
                        icon={extendLesson ? <DoubleRightOutlined /> : <DoubleLeftOutlined /> }
                      />
                    </div>
                  </div>
                  )
              }
            </div>
          )
        }
        </Show>
        <Lessons
          generateSubmodules={generateSubmodules}
          generateContents={generateContents}
          course={course}
          extendLesson={extendLesson}
          setExtendLesson={setExtendLesson}
          getCourse={getCourse}
          tokenLeft={tokenLeft}
          handleSortModules={handleSortModules}
          handleSortSubmodules={handleSortSubmodules}
          deleteCourse={deleteCourse}
          processingMessage={processingMessage}
        />
      </div>
  )
}

export default Home;
