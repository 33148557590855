import React from 'react';
import { Button, Card } from 'antd';
import { useHistory } from 'react-router-dom';
import "./index.css";

const ProjectCard = ({ project }) => {
  const history = useHistory();

  return (
    <Card>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: window.innerWidth > 600 ? 'row' : 'column', gap: 20 }}>
          <span style={{ fontWeight: 700 }}>{project.name}</span>
          <span style={{ display: 'flex', justifyContent: 'end', gap: 5 }}>
            <Button
              size='small'
              onClick={() => { history.push(`/studio/project/${project.id}/course/new`) }}
            >
              {project.courses.length} {project.courses.length > 0 ? 'courses' : 'course'}
            </Button>
            <Button
              size='small'
              onClick={() => { history.push(`/studio/project/${project.id}/details/details`) }}
            >
              Project details
            </Button>
          </span>
        </div>
      </div>
    </Card>
  )
}

export default ProjectCard;
