/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Dropdown, Space } from "antd";
import { MenuOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const DropdownMenu = ({ menuItems, label, icon=<MenuOutlined />, btnType="link", btnStyle, size }) => {
  const history = useHistory();
  const items = menuItems.map((item) => ({
    ...item,
    label: (
      <div style={{ textAlign: 'center', fontFamily: 'Outfit' }}>
        {item.label}
      </div>
    ),
    onClick: () => {
      if (item.path) {
        history.push(item.path);
      } else if (item.mailto) {
        window.location.href = item.mailto;
      } else if (item.url) {
        window.open(item.url, '_blank');
      } else if (item.onClick) {
        item.onClick()
      }
    }
  }))
  
  return (
    <Dropdown menu={{ items }}>
      <Button style={btnStyle} size={size || "small"} type={btnType}>
        <Space>
          {label}
          {icon}
        </Space>
      </Button>
    </Dropdown>
  )
}

export default DropdownMenu;
