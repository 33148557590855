import { Button, Dropdown } from "antd"
import { MenuOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getAuth, signOut } from "firebase/auth";

const MainMenu = ({ btnType, useLogoIcon, logoSize }) => {
  const history = useHistory();
  const idToken = localStorage.getItem("accessToken")

  const handleSignOut = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
      localStorage.clear();
      history.push("/signin")
    })
  }
  
  const menuItems = [
    {
      label: 'Home',
      key: 'home',
      path: '/home',
      show: true
    },
    {
      label: 'Pricing',
      key: 'pricing',
      path: '/pricing/token',
      show: true
    },
    {
      label: 'Studio',
      key: 'studio',
      path: '/studio/projects',
      show: !!idToken
    },
    // {
    //   label: 'Account',
    //   key: 'account',
    //   path: '/account',
    //   show: !!idToken
    // },
    // {
    //   label: 'Pricing',
    //   key: 'upgrade',
    //   path: '/pricing/token',
    //   show: idToken
    // },
    {
      label: 'Support',
      key: 'support',
      path: '/support',
      show: true
    },
    // {
    //   label: 'About',
    //   key: 'about',
    //   path: '/about',
    //   show: true
    // },
    {
      label: 'Account',
      key: 'account',
      path: '/account',
      show: !!idToken
    },
    {
      label: 'Login',
      key: 'signin',
      path: '/signin',
      show: !idToken
    },
    {
      label: 'Sign out',
      key: 'signout',
      show: !!idToken
    },
  ]

  return (
    <Dropdown
      menu={{
        items: menuItems.filter((item) => item.show)
          .map((item) => ({
            ...item,
            label: (
              <div style={{ width: 100, textAlign: 'center', fontFamily: 'Outfit' }}>
                {item.label}
              </div>
            ),
            onClick: () => {
              if (item.path) {
                history.push(item.path);
              } else if (item.mailto) {
                window.location.href = item.mailto;
              } else if (item.key === 'signout') {
                handleSignOut()
              } else if (item.url) {
                window.open(item.url, '_blank');
              }
            }
          }))
      }}
    >
      <Button style={{ color: 'black', padding: 0, height: useLogoIcon ? 27 : undefined, width: useLogoIcon ? 25 : undefined }} type={btnType || 'link'} >
        {
          useLogoIcon ?
          (
            <img  
              style={{ cursor: 'pointer', width: logoSize || 21 }}
              alt="chat2course"
              src="/logo.png"
            />
          )
          : <MenuOutlined />
        }
      </Button>
    </Dropdown>
  )
}

export default MainMenu