/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { SyncOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import useScrollToTop from '../../utils/useScrollToTop';
import { PageContext, usePageActionHandler } from '../../components/Page';
import { actionTypes } from "../../actions";
import { post } from "../../utils/fetch";
import { Card, Tooltip } from "antd";
import MainMenu from '../../components/MainMenu';
import { convertToLocaleTime } from "../../utils/date";
import TooltipButton from "../../components/TooltipButton";
import { abbreviateNumber } from "../../utils/utils";
import { AppContext } from "../../App";
import CustomTag from "../../components/CustomTag";

const getActivityMessage = {
  "ask-audience-profiler": (data) => {
    return `You are using a total of <b>${data.metadata.total_tokens}</b> tokens to chat with the audience profiler. `
  },
  "ask-document": (data) => {
    return `You are using <b>${data.metadata.total_tokens}</b> tokens to chat with the document assistant. `
  },
  "ask-tutor": (data) => {
    return `You are using <b>${data.metadata.total_tokens}</b> tokens to chat with the course tutor. `
  },
  "generate-audience-profile": (data) => {
    return `You are using <b>${data.metadata.total_tokens}</b> tokens to generate an audience profile. `
  },
  "generate-course-overview": (data) => {
    return `You are using <b>${data.metadata.total_tokens}</b> tokens to generate a course overview. `
  },
  "generate-custom-course-audience-profile": (data) => {
    return `You are using <b>${data.metadata.total_tokens}</b> tokens to generate an audience profile for a custom course. `
  },
  "generate-custom-course-submodule-content": (data) => {
    return `You are using <b>${data.metadata.total_tokens}</b> tokens to generate lesson content for a custom course. `
  },
  "generate-custom-course-submodules": (data) => {
    return `You are using <b>${data.metadata.total_tokens}</b> tokens to generate lessons for a custom course. `
  },
  "generate-module-description-semantic-search": (data) => {
    return `You are using <b>${data.metadata.total_tokens}</b> tokens to generate a semantic search for a module description. `
  },
  "generate-modules": (data) => {
    return `You are using <b>${data.metadata.total_tokens}</b> tokens to generate modules. `
  },
  "generate-modules-overview": (data) => {
    return `You are using <b>${data.metadata.total_tokens}</b> tokens to generate a modules overview. `
  },
  "generate-semantic-search-learning-goals": (data) => {
    return `You are using <b>${data.metadata.total_tokens}</b> tokens to generate a semantic search for learning goals. `
  },
  "generate-source-overview": (data) => {
    return `You are using <b>${data.metadata.total_tokens}</b> tokens to generate a source overview. `
  },
  "generate-submodule-content": (data) => {
    return `You are using <b>${data.metadata.total_tokens}</b> tokens to generate lesson content. `
  },
  "generate-submodule-semantic-search": (data) => {
    return `You are using <b>${data.metadata.total_tokens}</b> tokens to generate a lesson for semantic search. `
  },
  "generate-submodules": (data) => {
    return `You are using <b>${data.metadata.total_tokens}</b> tokens to generate lessons. `
  },
  "initialize-audience-profile": (data) => {
    return `You are using <b>${data.metadata.total_tokens}</b> tokens to initialize an audience profile. `
  },
  "initialize-audience-profile-semantic-search": (data) => {
    return `You are using <b>${data.metadata.total_tokens}</b> tokens to generate a semantic search to initialize an audience profile. `
  },
  "initialize-custom-course-audience-profile": (data) => {
    return `You are using <b>${data.metadata.total_tokens}</b> tokens to initialize an audience profile for a custom course. `
  },
  "initialize-modules-overview": (data) => {
    return `You are using <b>${data.metadata.total_tokens}</b> tokens to initialize the overview of modules. `
  },
  "summarize-custom-course-learning-goals": (data) => {
    return `You are using <b>${data.metadata.total_tokens}</b> tokens to summarize the learning goals for a custom course. `
  },
  "summarize-learning-goals": (data) => {
    return `You are using <b>${data.metadata.total_tokens}</b> tokens to summarize the learning goals. `
  },
  "initialize-transferred-course": (data) => {
    return `You are using <b>${data.metadata.total_tokens}</b> tokens to initialize transfer of your course from version 1. `
  },
  "generate-lesson-description": (data) => {
    return `You are using <b>${data.metadata.total_tokens}</b> tokens to generate lesson description. `
  },
  "get-course-language": (data) => {
    return `You are using <b>${data.metadata.total_tokens}</b> tokens to get course language.`
  },
}

const Activity = () => {
  useScrollToTop();
  const { appState } = useContext(AppContext);
  const { setError } = useContext(PageContext);
  const { addAction, removeAction } = usePageActionHandler();
  const [activity, setActivity] = useState([]);

  useEffect(() => {
    getUserActivity();
  }, [])

  const getUserActivity = async () => {
    const action = "getUserActivity";
    addAction(action, actionTypes[action].fetchMessage)
    await post(actionTypes[action].api)
    .then((res) => {
      setActivity(res.data.activity);
    })
    .catch((err) => {
      setError(err)
    })
    removeAction(action)
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 10, padding: 10 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Link to="/home">
          <img style={{ width: 22 }} alt="chat2course" src="/logo.png" />
        </Link>
        <MainMenu />
      </div>
      <Card style={{ border: 0, backgroundColor: '#c3e3ff', padding: 4 }}>
        <h2 style={{ fontFamily: 'Outfit' }}>Token Usage</h2>
        <div style={{ display: 'flex', justifyContent: 'space-between', gap: 5, alignItems: 'baseline' }}>
          <div style={{ display: 'flex', gap: 10, alignItems: 'baseline', flexDirection: window.innerWidth > 600 ? 'row' : 'column' }}>
            <CustomTag color="white"><span style={{ color: 'black' }}>Token balance: {abbreviateNumber(appState?.tokenPlan?.no_of_token_left)}</span></CustomTag>
            <CustomTag color="white"><span style={{ color: 'black' }}>Total token lifetime usage: {abbreviateNumber(activity.reduce((prev, curr) => prev + curr.metadata.total_tokens, 0))}</span></CustomTag>
          </div>
          <TooltipButton
            title="Refresh"
            type="default"
            onClick={getUserActivity}
            icon={SyncOutlined}
          />
        </div>
      </Card>
      <div style={{ overflow: 'auto', height: 'calc(100vh - 220px)', display: 'flex', gap: 10, flexDirection: 'column' }}>
        {
          activity.map((item) => {
            const prompt = getActivityMessage[item.name];
            return (
              <Card id={item.id}>
                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: window.innerWidth > 600 ? 'row' : 'column-reverse', gap: 10 }}>
                  <div style={{ display: 'flex', gap: 10, flexDirection: window.innerWidth > 600 ? 'row' : 'column' }}>
                    <span dangerouslySetInnerHTML={{__html: prompt ? prompt(item) : ""}} />
                    <Tooltip
                      title={
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                          <span>Id: {item.id}</span>
                          <span>Prompt tokens: {item.metadata.prompt_tokens}</span>
                          <span>Completion tokens: {item.metadata.completion_tokens}</span>
                        </div>
                      }
                    >
                      <b>Details</b>
                    </Tooltip>
                  </div>
                  <span style={{ textAlign: 'right' }}>{convertToLocaleTime(item.created_date)}</span>
                </div>
              </Card>
            )
          })
        }
      </div>
    </div>
  )
}

export default Activity;
