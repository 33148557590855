import { Button } from "antd"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ComingSoon = () => {
  const history = useHistory()

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20, width: '100vw', height: '100vh', alignItems: 'center' }}>
      <h3>Coming Soon!</h3>
      <ul>
          <li>Customizable Course Outlines</li>
          <li>AI Tutor</li>
          <li>Create Multiple Courses</li>
          <li>Use Your Own OpenAI API Key</li>
          <li>Support for GPT3.5 and GPT4</li>
          <li>Platform Tokens</li>
          <li>Download Courses as PDFs</li>
          <li>Content Editor</li>
      </ul>
      <span style={{ marginBottom: 20 }}>
      <Button onClick={() => { history.push("/home") }}>Return</Button>
      </span>
      <a style={{ textAlign: 'center' }} href="mailto:resz@chat2course.com">Contact</a>
    </div>
  )
}

export default ComingSoon;