import { Button, Card, Input, Spin } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { LoadingOutlined, SyncOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { List, arrayMove } from "react-movable";

import "./project.css";
import { post } from "../../utils/fetch";
import { actionTypes } from "../../actions";
import { PageContext, usePageActionHandler } from "../../components/Page";
import TabEditableCard from "../../components/TabEditableCard";
import { CourseContext } from "./Course";
import ModuleCard from "./ModuleCard";
import TooltipButton from "../../components/TooltipButton";
import DropdownMenu from "../../components/DropdownMenu";
import { ProjectContext } from "./Project";

const Modules = () => {
  const history = useHistory();
  const {
    isActionInProgress,
    setError,
    withConfirmation
  } = useContext(PageContext);
  const {
    getCourseDetails,
    courseData,
    handleSortModules,
  } = useContext(CourseContext);
  const { addAction, removeAction } = usePageActionHandler();
  const moduleFormRef = useRef();
  const { contentId, projectId } = useParams();
  const { handleDeleteCourseSuccess } = useContext(ProjectContext);

  const [showNewModuleForm, setShowNewModuleForm] = useState(false);
  const [enableDragModule, setEnableDragModule] = useState(false);
  const [containerOverflow, setContainerOverflow] = useState(!!courseData?.course.course_module.length);

  useEffect(() => {
    if (showNewModuleForm) {
      moduleFormRef.current?.scrollIntoView()
    }
  }, [showNewModuleForm])

  const addNewModule = async (values) => {
    const action = "addNewCourseModule";
    addAction(action)
    await post(actionTypes[action].api, {
      courseId: contentId,
      title: values.title,
      description: values.description
    })
      .then(async (res) => {
        await getCourseDetails()
          .then(() => {
            setShowNewModuleForm(false);
            removeAction(action)
          })
      })
      .catch((err) => {
        setError(err);
        setShowNewModuleForm(false);
        removeAction(action)
      })
  }

  const handleOnScroll = (e) => {
    setContainerOverflow(e.target.scrollHeight > e.target.clientHeight);
  }

  const deleteCourse = () => withConfirmation("deleteCourse",
  async (action) => {
    addAction(action)
    await post(actionTypes[action].api, {
      courseId: contentId,
    })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        removeAction(action);
        handleDeleteCourseSuccess(contentId);
        history.push(`/studio/project/${projectId}/course/new`)
      })
  })

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
      <div style={{ display: 'flex', gap: 5, justifyContent: 'flex-end', flexWrap: 'wrap' }}>
        <Button
          size="small"
          type="default"
          onClick={() => {
            setShowNewModuleForm(true);
          }}
          disabled={isActionInProgress()}
        >
          Add new module
        </Button>
        <Button
          size="small"
          type="default"
          onClick={() => { setEnableDragModule(!enableDragModule) }}
          disabled={isActionInProgress()}
          style={{ borderColor: enableDragModule ? 'blue' : undefined, color: enableDragModule ? 'blue' : undefined }}
        >
          Sort
        </Button>
        <TooltipButton
          title="Refresh"
          type="default"
          onClick={getCourseDetails}
          icon={SyncOutlined}
        />
        <TooltipButton
          title="Delete"
          type="default"
          onClick={deleteCourse}
          icon={DeleteOutlined}
        />
        <DropdownMenu
          btnType="default"
          icon={<MenuOutlined />}
          menuItems={[
            {
              label: 'Overview',
              key: 'overview',
              onClick: () => {
                history.push(`/project/${projectId}/course/${contentId}`)
              }
            },
            {
              label: 'View',
              key: 'editor',
              onClick: () => { history.push(`/learn/${contentId}`) },
            },
          ]}
        />
      </div>
      <div className="Modules-main-content" onScroll={handleOnScroll} style={{ paddingRight: containerOverflow ? 10 : 0 }}>
        <Card style={{ border: 0, backgroundColor: '#c3e3ff', padding: 4 }}>
          <h2 style={{ fontFamily: 'Outfit' }}>Table of Contents</h2>
        </Card>
        <Spin indicator={antIcon} spinning={isActionInProgress(["sortCourseModules"])}>
          <List
            values={courseData?.course?.course_module || []}
            onChange={({ oldIndex, newIndex }) => {
              handleSortModules(arrayMove(courseData?.course?.course_module, oldIndex, newIndex))
            }}
            renderList={({ children, props }) => (
              <div
                {...(enableDragModule ? props : {})}
                style={{
                  display: 'flex',
                  gap: 10,
                  flexWrap: 'wrap',
                  ...(enableDragModule ? props.style : {})
                }}
              >
                {children}
                {
                  showNewModuleForm && (
                    <TabEditableCard
                      title="Add new module"
                      style={{ width: '100%' }}
                      allowClose
                      editable
                      formOnly
                      onSubmit={addNewModule}
                      onClose={() => { setShowNewModuleForm(false) }}
                      isSubmitting={isActionInProgress("addNewCourseModule")}
                      form={{
                        initialValues: {
                          title: "",
                          description: ""
                        }
                      }}
                      data={[
                        {
                          name: 'title',
                          label: "Title",
                          input: Input
                        },
                        {
                          name: 'description',
                          label: "Description",
                          input: Input.TextArea,
                          inputProps: {
                            rows: 4,
                            maxLength: 1000,
                            showCount: true
                          }
                        }
                      ]}
                    />
                  )
                }
                <div ref={moduleFormRef} />
              </div>
            )}
            renderItem={({ value, index, props }) => {
              return (
                <ModuleCard
                  draggableProps={props}
                  key={value.id}
                  moduleData={value}
                  moduleIndex={index}
                  dragModuleModeEnabled={enableDragModule}
                />
              )
            }}
          />
        </Spin>
      </div>
    </div>
  )
}

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

export default Modules;
