import Show from "../../components/Show";
import { useContext } from "react";
import { PageContext } from "../../components/Page";
import MainMenu from "../../components/MainMenu";

const ProjectTopLeft = ({ project }) => {
  const {
    isActionInProgress,
  } = useContext(PageContext);

  return (
    <div style={{ width: 200, display: 'flex', gap: 10, flexDirection: 'column' }}>
    <div>
      <div style={{ display: 'flex', height: "100%", marginBottom: 44, marginTop: 2 }}>
        <MainMenu useLogoIcon />
      </div>
      <Show show={!isActionInProgress(["getProject"])}>
        <p style={{ fontSize: 20, fontWeight: 700, textAlign: 'center' }}>{project?.name}</p>
      </Show>
    </div>
  </div>
  )
}

export default ProjectTopLeft;
