import { Button,  Card, Skeleton, Spin } from "antd";
import {
  DoubleLeftOutlined,
  SyncOutlined,
  LoadingOutlined,
  DoubleRightOutlined,
  CrownOutlined,
  MenuOutlined
} from '@ant-design/icons';

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CustomTag from "../../components/CustomTag";
import MainMenu from "../../components/MainMenu";
import TooltipButton from "../../components/TooltipButton";
import { AppContext } from "../../App";
import { useContext, useState } from "react";
import { abbreviateNumber } from "../../utils/utils";
import { PageContext } from "../../components/Page";
import { arrayRemove } from "react-movable";
import { planType } from "../../constants";
import DropdownMenu from "../../components/DropdownMenu";

const aiModels = {
  "gpt-4": "GPT-4",
  "gpt-3.5-turbo-16k": "GPT-3.5",
  "gpt-4-turbo-preview": "GPT-4-Turbo",
  "gpt-4o": "GPT-4o",
}

const Home = ({
  generateSubmodules,
  generateContents,
  course,
  extendLesson,
  setExtendLesson,
  getCourse,
  tokenLeft,
  handleSortModules,
  handleSortSubmodules,
  processingMessage
}) => {
  const history = useHistory();
  const aiModel = localStorage.getItem('aiModel')
  const { appState } = useContext(AppContext);
  const { isActionInProgress, withConfirmation, setError } = useContext(PageContext);
  const coursePlan = appState?.[planType[course?.plan_type_id]];
  const [hoveredLesson, setHoveredLesson] = useState(null)

  const onDeleteModule = (moduleIndex) => withConfirmation("deleteModule",
    async () => {
      await handleSortModules(
        arrayRemove(course?.course_module, moduleIndex),
        course?.course_module[moduleIndex]
      )
      .then(async () => {
        await getCourse();
      })
    }
  )

  const onDeleteLesson = (moduleIndex, submoduleIndex, submodule) => withConfirmation("deleteSubmodule",
    async () => {
      await handleSortSubmodules(
        moduleIndex,
        arrayRemove(course?.course_module[moduleIndex].keypoints, submoduleIndex),
        submodule
      )
        .then(async () => {
          await getCourse();
        })
    }
  )

  return (
    <div style={{ width: extendLesson ? '100vw' : 550, display: window.innerWidth < 1000 && !extendLesson ? 'none' : 'flex', flexDirection: 'column', gap: 5 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', gap: 10, alignItems: 'center' }}>
        <div>
        </div>
        <div style={{ display: 'flex', gap: 3, justifyContent: 'end', alignItems: 'center' }}>
          {
            appState?.tokenPlan && (
              <Button size="small" onClick={() => { history.push("/account") }}>
                Tokens  {abbreviateNumber(tokenLeft || appState?.tokenPlan?.no_of_token_left)}
              </Button>
            )
          }
          <Button
            size="small"
            onClick={() => {
              if (localStorage.getItem("accessToken")) {
                history.push("/account")
              } else {
                history.push("/signin")
              }
            }}
            >
              {aiModels[aiModel || (appState?.tokenPlan?.is_trial === 0 ? "gpt-4" : "gpt-3.5-turbo-16k")]}
          </Button>
          <div style={{ marginLeft: 10 }}>
            <MainMenu useLogoIcon />
          </div>
        </div>
      </div>
      <Card size="small" style={{ backgroundColor: '#C3E3FF', height: 90, display: 'flex', flexDirection: 'column', gap: 20 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', gap: 20, margin: "5px 5px 0 5px" }}>
          <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
            <Button
              type="ghost"
              onClick={() => { setExtendLesson(!extendLesson) }}
              icon={extendLesson ? <DoubleRightOutlined /> : <DoubleLeftOutlined /> }
            />
            <h2 style={{ margin: '0', marginBottom: 3 }}>Table of Contents</h2>
          </div>
          <div style={{ display: 'flex', gap: 5 }}>
            <TooltipButton
              title="Refresh"
              type="ghost"
              onClick={getCourse}
              icon={SyncOutlined}
            />
            <DropdownMenu
              btnType="ghost"
              icon={<MenuOutlined />}
              menuItems={[
                {
                  label: 'Customize',
                  key: 'customize',
                  onClick: () => {
                    history.push(`/studio/project/${course?.project_id}/course/${course.id}`)
                  },
                  disabled: isActionInProgress() || !course?.id
                },
                {
                  label: 'View',
                  key: 'editor',
                  onClick: () => { history.push(`/learn/${course.id}`) },
                  disabled: !course?.id || course?.course_module?.length === 0
                },
              ]}
            />
          </div>
        </div>
      </Card>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 5, height: `calc(100vh - 160px)`, overflow: 'auto' }}>
          {
            !course?.course_module.length &&
            <Card style={{ height: '100%' }}>
              {processingMessage && <div style={{ marginBottom: 20 }}>{processingMessage}</div>}
              {
                course?.status_v2_id === 'processing' && <Skeleton active paragraph={{ rows: 2 }} />
              }
            </Card>
          }
          {
            course?.course_module?.map((item, index) => {
              return (
                <Card key={item.id}>
                  <div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                      <div style={{ display: 'flex', gap: 3, justifyContent: 'end', alignItems: 'center' }}>
                        {
                        item.status_id === 'processing' &&
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: 16,
                                marginRight: 5
                              }}
                              spin
                            />
                          }
                        />
                        }
                        <Button
                          type="link"
                          size="small"
                          disabled={
                            item?.keypoints.length === 0
                            || (course?.hands_on && index > 0 && !course?.course_module[index - 1]?.keypoints[course?.course_module[index - 1]?.keypoints.length - 1]?.content)
                          }
                          onClick={() => { generateContents(item.id) }}
                        >
                          Generate
                        </Button>
                        <Button
                          type="link"
                          size="small"
                          onClick={async () => {
                            await onDeleteModule(index)
                          }}
                          disabled={isActionInProgress()}
                        >
                          Delete
                        </Button>
                      </div>
                      <h3>{item.title}</h3>
                    </div>
                    {
                      item.description &&
                      <div style={{ whiteSpace: 'pre-wrap' }}>{item.description}</div>
                    }
                    <div style={{ display: 'flex', flexDirection: 'row-reverse', gap: 3 }}>
                      {
                        // (!course?.hands_on || availableFeatures.comprehensiveHandsOnLessons) && (
                        false && (
                          <Button
                            onClick={() => {
                              if (coursePlan?.is_trial) {
                                setError("This feature is not available when using free credits.")
                                return;
                              }
                              generateSubmodules(item)
                            }}
                            size="small"
                            icon={coursePlan?.is_trial ? <CrownOutlined style={{ fontSize: 11, color: 'gold' }} /> : undefined}
                          >
                            Comprehensive lessons
                          </Button>
                        )
                      }
                    </div>
                  </div>
                  <ul style={{ display: item?.keypoints.length ? 'flex' : 'none', flexDirection: 'column', gap: 5, marginTop: 20 }}>
                    {
                      item?.keypoints
                        .map((kp, kpIndex) => {
                          return (
                            <li>
                              <div style={{ display: 'flex', gap: 10 }} onMouseEnter={() => { setHoveredLesson(kp.id) }}>
                                <div style={{ cursor: 'pointer' }}>
                                  {kp.title}
                                </div>
                                <div style={{ display: 'flex', gap: 3 }}>
                                  {
                                    kp.status_id === 'generating-content' &&
                                    <Button
                                      style={{ height: 22, padding: '0 4px' }}
                                      onClick={() => { generateContents(item.id, kp) }}
                                    >
                                      Retry
                                    </Button>
                                  }
                                  {
                                    kp.status_id === 'ready' &&
                                    <CustomTag color="green" style={{ height: 'fit-content' }}>
                                      <a href={`/learn/${course.id}/${kp.id}`} target="_blank" rel="noreferrer">View</a>
                                    </CustomTag>
                                  }
                                  {
                                    kp.status_id === 'generating-content' &&
                                    <CustomTag
                                      color="blue"
                                      style={{ height: 'fit-content' }}
                                      icon={<SyncOutlined spin />}
                                    >
                                      Processing
                                    </CustomTag>
                                  }
                                </div>
                              </div>
                              {
                                hoveredLesson === kp.id &&
                                <div>
                                  <Button
                                    type="link"
                                    size="small"
                                    onClick={() => { generateContents(item.id, kp) }}
                                    disabled={isActionInProgress() || kp.status_id === "generating-content"}
                                  >
                                    Generate
                                  </Button>
                                  {
                                    kp.description &&
                                    <TooltipButton
                                      title={kp.description}
                                      type="link"
                                      text="Description"
                                    />
                                  }
                                  <Button
                                    type="link"
                                    size="small"
                                    onClick={async () => {
                                      await onDeleteLesson(index, kpIndex, kp)
                                    }}
                                    disabled={isActionInProgress()}
                                  >
                                    Delete
                                  </Button>
                                </div>
                              }
                            </li>
                          )
                        })
                    }
                  </ul>
                </Card>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default Home;
