import React, { useState } from 'react';
import { useQuill } from 'react-quilljs';
import { SaveOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ImageActions } from '@xeger/quill-image-actions';
import { ImageFormats } from '@xeger/quill-image-formats';

import CustomTag from '../../components/CustomTag';

import 'quill/dist/quill.bubble.css';
import './contentEditor.css';

const ContentEditor = ({ delta: _delta='', html='', onSave, onUploadImg }) => {
  const [delta, setDelta] = useState(_delta);
  const [isEditMode, setIsEditMode] = useState(false);
  const { quill, quillRef, Quill } = useQuill({
    theme: "bubble",
    modules: {
      imageActions: {},
      imageFormats: {},
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ align: [] }],
        [{ list: 'ordered'}, { list: 'bullet' }],
        [{ indent: '-1'}, { indent: '+1' }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['link', 'image'],
        [{ color: [] }, { background: [] }],
        ['clean'],
      ],
      clipboard: {
        matchVisual: false,
      },
    },
    formats: [
      'background', 'bold', 'color', 'code', 'italic', 'link', 'size', 
      'strike', 'script', 'underline', 'blockquote', 'header', 'indent', 'list', 
      'align', 'direction', 'code-block', 'formula',
      'image', 'height', 'width', 'float',
    ]
  });

  if (Quill && !quill) {
    const block = Quill.import('blots/block');
    block.tagName = 'div';
    Quill.register(block);
    Quill.register('modules/imageActions', ImageActions);
    Quill.register('modules/imageFormats', ImageFormats);
  }

  const insertToEditor = (url) => {
    const range = quill.getSelection();
    quill.insertEmbed(range.index, 'image', url);
  };

  const saveToServer = async (file) => {
    const body = new FormData();
    body.append('file', file);
    const imgUrl = await onUploadImg(file)
    insertToEditor(imgUrl)
  };

  const selectLocalImage = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      saveToServer(file);
    };
  };

  React.useEffect(() => {
    if (quill) {
      if (!_delta) {
        quill.clipboard.dangerouslyPasteHTML(html)
      } else {
        quill.setContents(_delta)
      }
      quill.getModule('toolbar').addHandler('image', selectLocalImage);
      quill.enable(isEditMode); 
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quill, html, _delta]);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'end', paddingBottom: 10, gap: 5  }}>
        {
          isEditMode &&
          <>
            <CustomTag color='blue'>Edit mode</CustomTag>
            <Button
              icon={<SaveOutlined />}
              onClick={async () => {
                const content = quill.getContents();
                const text = quill.getText()
                await onSave(content, text);
                setDelta(content)
              }}
            />
          </>
        }
        <Button
          icon={isEditMode ? <CloseOutlined /> : <EditOutlined />}
          onClick={() => {
            setIsEditMode(!isEditMode)
            quill.enable(!isEditMode);
            if (isEditMode) {
              if (delta) {
                quill.setContents(delta)
              } else {
                quill.clipboard.dangerouslyPasteHTML(html)
              }
            }
          }}
        />
      </div>
      <div className='content-editor' style={{ position: 'relative' }}>
        <div ref={quillRef} />
        <div style={{ display: isEditMode ? 'none' : 'block', width: '100%', height: '100%', position: 'absolute', top: 0 }} />
      </div>
    </div>
  );
};

export default ContentEditor;
