import { Button, Card } from 'antd';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Meta from 'antd/es/card/Meta';
import { useContext } from 'react';

import './pricing.css'
import MainMenu from '../../components/MainMenu';
import useScrollToTop from '../../utils/useScrollToTop';
import { PageContext, usePageActionHandler } from '../../components/Page';
import { post } from '../../utils/fetch';
import { AppContext } from "../../App";

const subscriptionPlans = [
  {
    name: 'Lifetime License Promotion',
    price: 259,
    promoPrice: 259,
    description: <>Limited time promotion. Purchase once and gain lifetime access to our <b>Unlimited Plan</b>.</>,
    planId: 'lifetime',
    hightlight: true,
    buyBtnText: 'Purchase',
    features: [],
    point: 4
  },
  // {
  //   name: 'Free Plan',
  //   price: 0,
  //   promoPrice: 0,
  //   description: `Step into the future of course creation with our AI-powered Course Builder, create up to 3 courses / month for free.`,
  //   buyBtnText: 'Sign up',
  //   planId: 'self_api_key_free',
  //   free: true,
  //   features: [],
  //   point: 1
  // },
  // {
  //   name: 'Student Plan',
  //   price: 10,
  //   promoPrice: 10,
  //   description: `A comprehensive plan for students and knowledge seekers!`,
  //   planId: 'self_api_key_student',
  //   hightlight: false,
  //   buyBtnText: 'Upgrade',
  //   features: [
  //     "Create up to 10 courses every month",
  //     "Upgrade your learning experience at an affordable price"
  //   ],
  //   point: 2
  // },
  {
    name: 'Unlimited Plan',
    price: 39,
    promoPrice: 39,
    description: ` Explore endless possibilities with our premium Unlimited Plan that allows you to generate infinite courses each month.`,
    planId: 'self_api_key_unlimited',
    hightlight: false,
    buyBtnText: 'Subscribe',
    features: [
      "Unlimited course generation",
      "Download course as PDF",
      "Fully customized course modules & lessons"
    ],
    point: 3
  }
];

const Pricing = () => {
  useScrollToTop()
  const userEmail = localStorage.getItem('email');
  const { appState } = useContext(AppContext);
  const history = useHistory()
  const {
    setError,
    isActionInProgress
  } = useContext(PageContext);
  const { addAction, removeAction } = usePageActionHandler();
  const currentPlan = subscriptionPlans.find((item) => appState?.selfApiPlan?.plan_id === item.planId);
  // const promotionCount = appState?.appSetting?.appSettings?.find((item) => item.id === 'lifetime-promo')?.value;

  const handleGeneratePaymentLink = async (productKey) => {
    const api = `/create-token-payment-link`;
    const action = `${api}?${productKey}`;
    addAction(action)
    await post(api, {
      productKey,
      url: window.location.protocol + '//' + window.location.host
    })
      .then((res) => {
        window.location.replace(`${res.data.paymentLink}?prefilled_email=${encodeURIComponent(userEmail)}`)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        removeAction(action)
      })
  }

  return (
    <div className='pricing' style={{ padding: window.innerWidth > 600 ? 40 : 20, maxWidth: 1080, margin: 'auto' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Link to="/home">
          <img style={{ width: 26 }} alt="chat2course" src="/logo.png" />
        </Link>
        <MainMenu />
      </div>
      <Card style={{ backgroundColor: '#9feef4', borderColor: 'black', marginTop: 10, padding: 20, marginBottom: 10  }}>
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Button style={{ height: 'auto' }}  onClick={() => { history.push('/pricing/token') }}>
            <div style={{ whiteSpace: 'break-spaces', height: 'auto' }}>Need extra tokens?</div>
          </Button>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h1 style={{ marginTop: 60 }} className='pricing-hero-font'>Subscription Plan</h1>
          <p style={{ fontSize: 20, lineHeight: 1.4, textAlign: 'center', margin: 'auto', maxWidth: 800, marginBottom: 40 }}>
            Subscribe and use your own OpenAI API key! This enables users with an existing OpenAI API key to fully access our AI Course Builder at a fixed monthly fee. <b>GPT-3.5</b> & <b>GPT-4</b> supported!
          </p>
        </div>
      </Card>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: 10, marginBottom: 40 }}>
        {
          subscriptionPlans.map((item) => {
            return (
              <Card
                hoverable
                className={item.hightlight ? 'api-plan-card' : undefined}
                key={item.name}
                style={{
                  width: window.innerWidth > 730 ? 'calc(50% - 5px)' : '100%',
                  minWidth: 320,
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                  borderColor: 'black',
                  backgroundColor: item.hightlight ? '#fff8d3' : undefined,
                }}
              >
                <Meta
                  title={(
                    <div style={{ whiteSpace: 'break-spaces', fontSize: 18 }}>
                      {item.name} - {item.price > item.promoPrice ? <>$<s>{item.price}</s> {item.promoPrice}</> : `$${item.price}`}
                    </div>
                  )}
                  description={
                    <div style={{ color: 'black', padding: '20px 20px 0 20px' }}>
                      <p style={{ fontSize: 16 }}>
                        {item.description}
                      </p>
                      <ul>
                        {
                          item.features.map((feature) => {
                            return (
                              <li>{feature}</li>
                            )
                          })
                        }
                      </ul>
                      <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: 20 }}>
                        {
                          appState?.selfApiPlan?.plan_id === item.planId
                            ? (
                              <Button type="primary" onClick={() => { history.push("/account") }}>
                                Current plan
                              </Button>
                            )
                            : (
                              <Button
                                loading={isActionInProgress(`/create-token-payment-link?${item.planId}`)}
                                disabled={isActionInProgress()}
                                onClick={() => {
                                  if (userEmail && !item.free && localStorage.getItem("openAiApiKeyV2")) {
                                    handleGeneratePaymentLink(item.planId)
                                    return;
                                  }

                                  if (userEmail && !item.free && !localStorage.getItem("openAiApiKeyV2")) {
                                    setError(`Go to your "Account" page & insert your OpenAI API key`)
                                    return;
                                  }

                                  history.push('/signin')
                                }}
                              >
                                {item.buyBtnText}
                              </Button>
                            )
                        }
                      </div>
                    </div>
                  }
                />
              </Card>
            )
          })
        }
      </div>
    </div>
  )
}

export default Pricing;