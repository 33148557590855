/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button } from "antd";
import { DownOutlined } from '@ant-design/icons';

import DropdownMenu from "./DropdownMenu";

const PageActions = ({ actions }) => {

  if (window.innerWidth > 600) {
    return (
      <div style={{ display: 'flex', gap: 5 }}>
        {
          actions.map((item) => (
            <Button
              size="small"
              icon={item.icon}
              onClick={item.onClick}
              loading={item.loading}
              disabled={item.disabled}
            >
              {item.text}
            </Button>
          ))
        }
      </div>
    )
  }

  return (
    <DropdownMenu
      label="Actions"
      icon={<DownOutlined />}
      btnType="default"
      menuItems={
        actions.map((item) => ({
          ...item,
          label: item.text
        }))}
    />
  )
}

export default PageActions;
