import React from 'react';
import { Button, Card, Tag } from 'antd';
import Meta from 'antd/es/card/Meta';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import "./homeV3.css"
import useScrollToTop from '../../utils/useScrollToTop.js';
import MainMenu from "../../components/MainMenu.jsx"
import TruncatedText from '../../components/TruncatedText.jsx'; 

const testimonial = [
  {
    name: "Franco Gericke",
    img: "https://chat2stats.imgix.net/IMG_20230623_111237.jpg",
    comment: "Being a PhD student, I have found Chat2Course to be an incredibly useful tool in my academic journey. With its AI-powered personal course builder, I can craft a tailored and comprehensive learning experience that helps me manage my demanding research projects and presentations with ease. One of the most impressive features of Chat2Course is its integration of GPT-4 technology. The AI tutor is incredibly helpful in addressing complex subject matters and difficult questions that often arise during my research. Its interactive and adaptive nature has improved my ability to understand and explore advanced theoretical concepts, which has been instrumental in my aiding me in my academic endeavours. It provides golden nuggets of information in the way that you learn best. This way I am able to grasp complicated topics in a fraction of the time. All in all, Chat2Course is an indispensable resource for every student. Its adaptability, integration of advanced AI technology, and supportive community make it a valuable asset in navigating the challenges of your academic journey. If you are looking for a tool to streamline your studies, I highly recommend Chat2Course.",
    socialProfile: "https://www.researchgate.net/profile/Franco-Gericke",
  },
  {
    name: "Andrew J. Leavitt",
    img: "https://chat2stats.imgix.net/andrew.jpg",
    comment: "Thank you for this revolutionary product. I am absolutely thrilled. It has truly changed the way we conduct business.",
    socialProfile: "https://www.linkedin.com/in/andrew-leavitt-28a614194",
  },
  {
    name: "Ryan Kmetz",
    img: "https://chat2stats.imgix.net/ryan.jpg",
    comment: "Chat2Course enabled me to craft a bespoke GIS curriculum and course content for online learning. The tool helped me to aggregate my thoughts and produce a great foundation for the content.",
    socialProfile: "https://ryankmetz.com/",
  }
]

const pdf = [
  {
    url: "https://chat2stats.imgix.net/dg_mark_cjdwhc.png",
    download: "https://drive.google.com/file/d/1Vn8XtS2l0eEc9__B-_sYqXsz-4wua8PM/view?usp=sharing"
  },
  {
    url: "https://chat2stats.imgix.net/wkfowrof.png" ,
    download: "https://drive.google.com/file/d/1jUG_R_oAJqRMDUC3GkJxNvjISgIDAWIX/view?usp=sharing"
  }
]

export const Menu = () => {
  const history = useHistory();

  return (
    <div style={{ backgroundRepeat: 'no-repeat', backgroundImage: undefined, display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
      <img style={{ cursor: 'pointer', width: window.innerWidth > 600 ? 32 : 18, alignItems: 'center', height: "100%" }} onClick={() => { history.push('/') }} alt="chat2course" src="/logo.png" />
      <MainMenu />
    </div>
  )
}

const Home = () => {
  useScrollToTop()

  const backgroundSize = window.innerWidth > 600
    ? 2 / 3 * window.innerWidth
    : window.innerWidth

  return (
    <div className='home-v3' style={{ backgroundSize,  backgroundRepeat: 'no-repeat', backgroundImage: `url("https://chat2stats.imgix.net/Untitled%20design%20(11).png")` }}>
      <div style={{ flexDirection: 'column', width: '100%', display: 'flex', justifyContent: 'center', }}>
        <div style={{ maxWidth: 1080, padding: '48px 24px', display: "flex", gap: 60, flexDirection: "column", alignSelf: "center", width: "100%", minHeight: "calc(100vh)" }}>
          <Menu />
          <div style={{ margin: '60px 0', minHeight: 'calc(100vh - 380px)', }}>
            <div style={{ display: 'flex', justifyContent: 'end', marginBottom: 40 }}>
            </div>
            <div className='home-hero-font' style={{ padding: window.innerWidth > 575 ? '0 40px' : '0 20px', marginBottom: 20, textAlign: 'right', marginLeft: 'auto', maxWidth: 800 }}>
              <b className='home-title' style={{ color: '#0390ff', fontSize: window.innerWidth > 575 ? 68 : 42 }}>Intelligent<br /> Course Development<br /> Assistant</b>
            </div>
            <div className='home-hero-font' style={{ padding: window.innerWidth > 575 ? '0 40px' : '0 20px', marginBottom: window.innerWidth > 700 ? 120 : 80, textAlign: 'right', marginLeft: 'auto', maxWidth: window.innerWidth > 700 ? '600px' : '100%' }}>
              <div style={{ fontSize: window.innerWidth > 700 ? 23 : 17, color: "#4e4e4e" }}>
                Supercharge Your Course Development Workflow with AI-Driven Solution
              </div>
            </div>
          </div>
        </div>
        <div style={{ maxWidth: 900, alignSelf: 'center' }}>
          <h2 className='section-title' style={{ width: '80%' }}>
            1. Start with a chat
          </h2>
          <div style={{ marginTop: 40, display: 'flex', gap: 10, flexWrap: 'wrap', justifyContent: 'center' }}>
            <img
              src="https://chat2stats.imgix.net/chat_shot.png"
              alt="xx"
              style={{  borderRadius: 10, width: "100%", maxWidth: 900, height: "auto" }} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row-reverse', margin: 20 }}>
            <Link to="/get-started">
              <Button size='large'>Try now</Button>
            </Link>
          </div>
        </div>
        <div  style={{ maxWidth: 900, alignSelf: 'center', marginTop: 80 }}>
          <h2 className='section-title' style={{ width: '80%' }}>
            2. Customize the output
          </h2>
          <div style={{ marginTop: 70, display: 'flex', gap: 10, flexWrap: 'wrap', justifyContent: 'center' }}>
            <img
              src="https://chat2stats.imgix.net/home_wysywig_shot.png"
              alt="xx"
              style={{ boxShadow: "rgb(171 171 171 / 20%) -20px -18px 0px 0px", borderRadius: 10, width: "100%", maxWidth: 800, height: "auto" }} />
          </div>
        </div>
        <div  style={{ marginTop: 80, marginBottom: 20 }}>
          <h2 className='section-title' style={{ width: '80%' }}>
            3. Export!
          </h2>
          <div style={{ marginTop: 70, display: 'flex', gap: 10, flexWrap: 'wrap', justifyContent: 'center' }}>
            {
              pdf.map((item) => (
                <a href={item.download} target='_blank' rel="noreferrer">
                  <img src={item.url} alt="xx" style={{ boxShadow: "0 3px 3px 0px rgb(0 0 0 / 0.2)", borderRadius: 10, width: 280, height: 410 }} />
                </a>
              ))
            }
          </div>
        </div>
        <div style={{ minHeight: 500, justifyContent: 'end', display: 'flex', flexDirection: 'column', background: 'linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 0%, #6fddff 100%)' }}>
          <div style={{ margin: '120px 0' }}>
            <h2 className='section-title' style={{ width: '80%' }}>
              Hear from Our Users
            </h2>
            <div className='testimonial-card' style={{ marginTop: 40, display: 'flex', gap: 10, flexWrap: 'wrap', justifyContent: 'center' }}>
              {
                testimonial.map((item) => (
                  <Card
                    style={{
                      width: window.innerWidth > 730 ? 'calc(50% - 10px)' : '100%',
                      minWidth: 300,
                      maxWidth: 350,
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                      height: '100%'
                    }}
                  >
                    <Meta
                      description={
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 20, textAlign: 'center', fontSize: 16, color: '#1e1e1e', lineHeight: 1.2 }}>
                          <TruncatedText str={item.comment} maxLength={200} />
                          <div style={{ display: 'flex', alignItems: 'center', gap: 20, justifyContent: 'end' }}>
                            <a href={item.socialProfile} target='_blank'  style={{ textAlign: 'center' }} rel="noreferrer">
                              {item.name}
                            </a>
                            <img style={{ width: 50, height: 50, objectFit: 'cover', borderRadius: 100 }} alt="example" src={item.img} />
                          </div>
                        </div>
                      }
                    />
                  </Card>
                ))
              }
            </div>
          </div>
          <h2 style={{ width: '80%', fontWeight: 700, maxWidth: 800, color: 'black', fontSize: window.innerWidth > 600 ? 40 : 32, textAlign: 'center', alignSelf: 'center' }}>
            Discover the new revolutionary way of designing effective and engaging courses tailored to your needs.
          </h2>
            <div style={{ display: 'flex', alignSelf: 'center', margin: window.innerWidth > 600 ? '20px 0 120px 0' : '20px 0 80px 0', gap: 5 }}>
              <Link to="/signin">
                <Button size="large">Register</Button>
              </Link>
              <Link to="/pricing/token">
                <Button size="large">Pricing</Button>
              </Link>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Home;