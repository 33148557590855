import Home from "./pages/home/Home";
import Signin from './pages/auth/Auth';
import ComingSoon from './pages/coming-soon/ComingSoon';
import Learn from './pages/learn/Learn';
import Projects from './pages/projects/Projects';
import AddNewProject from './pages/projects/AddNewProject';
import Project from './pages/project/Project';
import Activity from './pages/activity/Activity.jsx';
import Account from './pages/account/AccountV2.jsx';
import TokenPricing from './pages/pricing-v2/Token.jsx';
import SelfApiPricing from "./pages/pricing-v2/ApiPlan.jsx"
import Support from './pages/support/Support.jsx';
import HomeV3 from "./pages/home-v3/HomeV3.jsx";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";

import ProtectPage from './components/ProtectPage';
import Page from "./components/Page";
import AdminPage from "./pages/admin/Admin.jsx";
import AddNewCourse from "./pages/project/AddNewCourse.jsx";
import Header from "./components/Header.jsx";
import Template from "./pages/template/Template.jsx"
import CourseCover from "./pages/pdf/CourseCover.jsx";
import CourseLesson from "./pages/pdf/CourseLesson.jsx";
import CourseTableOfContents from "./pages/pdf/CourseTableOfContents.jsx";

const GetStarted = () => {
  const history = useHistory();

  if (localStorage.getItem("accessToken")) {
    history.push("/studio/projects");
    return;
  }

  return (
    <Header>
      <div>
        <AddNewCourse />
      </div>
    </Header>
  )
}

const routeConfig = [{
  route: "/",
  components: [
    Page,
    { component: Home, props: { exact: true } }
  ]
}, {
  route: "/pdf/course/cover/:courseId",
  components: [
    { component: CourseCover, props: { exact: true } }
  ]
}, {
  route: "/pdf/course/lesson/:lessonId",
  components: [
    { component: CourseLesson, props: { exact: true } }
  ]
}, {
  route: "/pdf/course/table-of-contents/:courseId",
  components: [
    { component: CourseTableOfContents, props: { exact: true } }
  ]
}, {
  route: "/get-started",
  components: [
    Page,
    {
      component: GetStarted,
      props: { exact: true }
    }
  ]
}, {
  route: "/home",
  components: [
    { component: HomeV3, props: { exact: true } }
  ]
},  {
  route: "/edit/:courseId/:submoduleId",
  components: [
    { component: Template, props: { exact: true } }
  ]
}, {
  route: "/project/:projectId/course/:courseId",
  components: [
    { component: Page, props: { initialActions: ["getUser"] } },
    { component: Home, props: { exact: true } }
  ]
},  {
  route: "/project/:projectId",
  components: [
    { component: Page, props: { initialActions: ["getUser"] } },
    { component: Home, props: { exact: true } }
  ]
}, {
  route: "/signin",
  components: [
    { component: Signin, props: { exact: true } }
  ]
}, {
  route: "/coming-soon",
  components: [
    { component: ComingSoon, props: { exact: true } }
  ]
}, {
  route: "/learn/:courseId",
  components: [
    { component: Page, props: { initialActions: ["getUser"] } },
    { component: Learn, props: { exact: true } }
]}, {
  route: "/learn/:courseId/:submoduleId",
  components: [
    { component: Page, props: { initialActions: ["getUser"] } },
    { component: Learn, props: { exact: true } }
]}, {
  route: "/studio/projects",
  components: [
    ProtectPage,
    { component: Page },
    { component: Projects, props: { exact: true } }
]}, {
  route: "/studio/project/new",
  components: [
    ProtectPage,
    Page,
    { component: AddNewProject, props: { exact: true } }
]}, {
  route: "/studio/project/:projectId/:type/:contentId",
  components: [
    ProtectPage,
    { component: Page, props: { initialActions: ["getUser"] } },
    { component: Project, props: { exact: true } }
]}, {
  route: "/activity/token",
  components: [
    ProtectPage,
    { component: Page, props: { initialActions: ["getUser"] } },
    { component: Activity, props: { exact: true } }
]}, {
  route: "/pricing/token",
  components: [
    Page,
    { component: TokenPricing, props: { exact: true } }
]}, {
  route: "/account",
  components: [
    ProtectPage,
    { component: Page, props: { initialActions: ["getUser"] } },
    { component: Account, props: { exact: true } }
]}, {
  route: "/admin",
  components: [
    // ProtectPage,
    { component: Page },
    { component: AdminPage, props: { exact: true } }
]}, {
  route: "/pricing/self-api-key",
  components: [
    { component: Page, props: { initialActions: ["getUser"] } },
    { component: SelfApiPricing, props: { exact: true } }
]}, {
  route: "/support",
  components: [
    Support
]},];

export default routeConfig;
