const planType = {
  "self_api_key_plan": "selfApiPlan",
  "storage_plan": "storagePlan",
  "token_v2": "tokenPlan"
};

const ERRORS = {
  "pleaseUpgrade": "Upgrade your plan to use this feature."
}

export {
  planType,
  ERRORS
}