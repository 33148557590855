import { Alert, Button, Card, Input } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { DownOutlined, UpOutlined, EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { arrayRemove } from "react-movable";

import { post } from "../../utils/fetch";
import { actionTypes } from "../../actions";
import { PageContext, usePageActionHandler } from "../../components/Page";
import TabEditableCard from "../../components/TabEditableCard";
import { CourseContext } from "./Course";
import TooltipButton from "../../components/TooltipButton";

const SubmoduleCard = ({
  draggableProps,
  submodule,
  closeAll,
  moduleId,
  moduleIndex,
  submoduleIndex
}) => {
  const {
    isActionInProgress,
    setError,
    withConfirmation
  } = useContext(PageContext);
  const {
    getSubmodules,
    handleUpdateSubmodulesSuccess,
    handleSortSubmodules,
  } = useContext(CourseContext);
  const { contentId } = useParams();
  const [more, setMore] = useState(false);
  const { addAction, removeAction } = usePageActionHandler();
  const [showUpdateSubmoduleForm, setShowUpdateSubmoduleForm] = useState(false);

  useEffect(() => {
    setMore(closeAll)
  }, [closeAll])

  const deleteSubmodule = () => withConfirmation("deleteSubmodule",
    async () => {
      handleSortSubmodules(
        moduleIndex,
        arrayRemove(getSubmodules(moduleIndex), submoduleIndex),
        submodule
      )
    }
  )

  const updateSubmodule = async (values) => {
    const action = "updateSubmoduleBasicDetails";
    addAction(action)
    await post(actionTypes[action].api, {
      courseId: contentId,
      moduleId: moduleId,
      submoduleId: submodule.id,
      title: values.title,
      description: values.description
    })
      .then(() => {
        handleUpdateSubmodulesSuccess(moduleIndex, submoduleIndex, values.title, values.description)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setShowUpdateSubmoduleForm(false)
        removeAction(action)
      })
  }

  return (
    <Card {...draggableProps} style={{ ...draggableProps.style, cursor: "grab", width: '100%' }} size="small">
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <div style={{ display: 'flex', gap: 10, justifyContent: 'space-between', flexDirection: window.innerWidth > 600 ? 'row' : 'column' }}>
          {
            !showUpdateSubmoduleForm && (
              <div style={{ display: 'flex', gap: 10 }}>
                <div style={{ fontWeight: more ? 600 : undefined }}>{submodule.title}</div>
              </div>
            )
          }
          {
            !showUpdateSubmoduleForm && (
              <div style={{ display: 'flex', gap: 5, marginLeft: window.innerWidth > 600 ? 'auto' : undefined, flexWrap: 'wrap' }}>
                {
                  submodule.status_id === 'ready' &&
                  (
                    <a style={{ color: 'green' }} href={`/learn/${contentId}/${submodule.id}`} target="_blank" rel="noreferrer">
                      <TooltipButton
                        title="View"
                        type="default"
                        onClick={() => { setShowUpdateSubmoduleForm(!showUpdateSubmoduleForm) }}
                        disabled={isActionInProgress()}
                        icon={EyeOutlined}
                      />
                    </a>
                  )
                }
                <TooltipButton
                  title="Edit lesson"
                  type="default"
                  onClick={() => { setShowUpdateSubmoduleForm(!showUpdateSubmoduleForm) }}
                  disabled={isActionInProgress()}
                  icon={EditOutlined}
                />
                <TooltipButton
                  title="Delete lesson"
                  type="default"
                  onClick={deleteSubmodule}
                  disabled={isActionInProgress()}
                  icon={DeleteOutlined}
                />
                <TooltipButton
                  title="Toggle description"
                  type="default"
                  onClick={() => { setMore(!more)  }}
                  disabled={isActionInProgress()}
                  icon={more ? UpOutlined : DownOutlined}
                />
              </div>
            )
          }
        </div>
        {
          more && !showUpdateSubmoduleForm && (
            <div style={{ padding: 10, whiteSpace: 'pre-line' }}>
              {
                submodule.description ||
                <Alert
                  style={{ border: 0 }}
                  message="Tips"
                  description={
                    <div>
                      <p>Use descriptions to further refine and direct the AI in generating content that focuses on specific key points or includes given text material.</p>
                      <Button size="small" onClick={() => { setShowUpdateSubmoduleForm(!showUpdateSubmoduleForm) }} type="link">Add description</Button>
                    </div>
                  }
                />
              }
            </div>
          )
        }
        {
          showUpdateSubmoduleForm && (
            <TabEditableCard
              title="Edit lesson"
              unstyle
              style={{ width: '100%', padding: 10 }}
              onClose={() => { setShowUpdateSubmoduleForm(false); }}
              allowClose
              editable
              formOnly
              onSubmit={updateSubmodule}
              isSubmitting={isActionInProgress("updateSubmoduleBasicDetails")}
              form={{
                initialValues: {
                  title: submodule.title,
                  description: submodule.description
                }
              }}
              data={[
                {
                  name: 'title',
                  label: "Title",
                  input: Input
                },
                {
                  name: 'description',
                  label: "Description",
                  input: Input.TextArea,
                  inputProps: {
                    rows: 5,
                    maxLength: 4000,
                    showCount: true,
                    placeholder: 'Use descriptions to further refine and direct the AI in generating content that focuses on specific key points or includes given text material.',
                  }
                }
              ]}
            />
          )
        }
      </div>
    </Card>
  )
}

export default SubmoduleCard;
