import React, { useState } from 'react';
import { useQuill } from 'react-quilljs';
import { SaveOutlined, DoubleRightOutlined, EditOutlined, DoubleLeftOutlined, CloseOutlined } from '@ant-design/icons';
import { Alert, Button } from 'antd';

import CustomTag from '../../components/CustomTag';
import Markdown from '../../components/Markdown';

import 'quill/dist/quill.bubble.css';
import './overviewEditor.css';

const OverviewEditor = ({ delta: _delta='', html='', markdown, onSave, onGenerateLesson, setExtendLesson, extendLesson }) => {
  const [delta, setDelta] = useState(_delta);
  const [isEditMode, setIsEditMode] = useState(false);
  const { quill, quillRef, Quill } = useQuill({
    theme: "bubble",
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered'}, { list: 'bullet' }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['clean'],
      ],
      clipboard: {
        matchVisual: false,
      },
    },
    formats: [
      'bold', 'color', 'code', 'italic', 
      'strike', 'script', 'underline', 'blockquote', 'header', 'list', 
      'align', 'direction', 'code-block', 'formula',
    ]
  });

  if (Quill && !quill) {
    const block = Quill.import('blots/block');
    block.tagName = 'div';
    Quill.register(block);
  }

  React.useEffect(() => {
    if (quill) {
      if (!_delta) {
        quill.clipboard.dangerouslyPasteHTML(html)
      } else {
        quill.setContents(_delta)
      }
      quill.enable(isEditMode); 
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quill, html, _delta]);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'end', marginBottom: 20, gap: 5 }}>
        {
          isEditMode ?
          (
            <>
              <CustomTag color='blue'>Edit mode</CustomTag>
              <Button
                icon={<SaveOutlined />}
                onClick={async () => {
                  const content = quill.getContents();
                  const text = quill.getText()
                  await onSave(content, text);
                  setDelta(content)
                }}
              />
            </>
          ) :
          (
            <Button
              type='primary'
              onClick={onGenerateLesson}
            >
              Generate table of contents
            </Button> 
          )
        }
        <Button
          icon={isEditMode ? <CloseOutlined /> : <EditOutlined />}
          onClick={() => {
            setIsEditMode(!isEditMode)
            quill.enable(!isEditMode);
            if (isEditMode) {
              if (delta) {
                quill.setContents(delta)
              } else {
                quill.clipboard.dangerouslyPasteHTML(html)
              }
            }
          }}
        />
        {
          window.innerWidth < 1000 &&
          (
            <Button
              onClick={() => { setExtendLesson(!extendLesson) }}
              icon={extendLesson ? <DoubleRightOutlined /> : <DoubleLeftOutlined /> }
            />
          )
        }
      </div>
      {
        !isEditMode && (
          <Alert
            description={
              <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                <div>
                  This course overview serves as a guide to ensure all lessons created match your chosen theme.
                  You can further customize it to your preferences.
                </div>
              </div>
            }
            type="info"
          />
        )   
      }
      <div className="overview-editor" style={{ marginTop: 40 }}>
        {
          (delta || html) ?
          <div ref={quillRef} /> :
          (
            <Markdown className="overview-md-editor">
              {markdown}
            </Markdown>
          )
        }
      </div>
    </div>
  );
};

export default OverviewEditor;
