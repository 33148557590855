import { Button, Card } from 'antd';
import './pricing.css';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useScrollToTop from '../../utils/useScrollToTop';

import PriceSlider from "./PriceSlider"
import { useContext, useState } from 'react';
import { post } from '../../utils/fetch';
import { PageContext, usePageActionHandler } from '../../components/Page';
import { actionTypes } from '../../actions';
import MainMenu from '../../components/MainMenu';
import Markdown from '../../components/Markdown';

const tokenExplanation = `
# What are tokens used for?

Tokens provide you with the gateway to our innovative range of AI-enabled features. Acquiring tokens allows you to harness the power of our cutting-edge technology to create personalized courses or access customized learning content.

Tokens can be utilized in various ways throughout the course creation process:

- **Initializing Your Course**
- **Generating Modules & Lessons**
- **Generating Lesson Content**
- **AI Assistant**

## How many courses can I create with tokens?
The number of courses that can be created per tokens can vary. Typically, 100k tokens can generate a single comprehensive course with around 40 lessons or multiple shorter courses.

Additional features:

- View & download your course as a PDF
- Template selection (Cover)
- Customizable table of contents 
`;

const Pricing = () => {
  useScrollToTop()
  const {
    setError
  } = useContext(PageContext);
  const { addAction, removeAction } = usePageActionHandler();
  const history = useHistory()

  const userEmail = localStorage.getItem('email');
  const [decimalInputValue, setDecimalInputValue] = useState(10);

  const handleGenerateBuyTokenPaymentLink = async () => {
    const action = 'createTokenPaymentLink';
    addAction(action);
    await post(actionTypes[action].api, {
      productKey: 'token_v2',
      amount: decimalInputValue * 100,
      url: window.location.protocol + '//' + window.location.host
    })
      .then((res) => {
        localStorage.removeItem('openAiApiKey')
        window.location.replace(`${res.data.paymentLink}?prefilled_email=${encodeURIComponent(userEmail)}`)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        removeAction(action);
      })
  }

  return (
    <div className='pricing' style={{ backgroundColor: 'white', padding: window.innerWidth > 600 ? 40 : 20, maxWidth: 1080, margin: 'auto' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Link to="/home">
          <img style={{ width: 26 }} alt="chat2course" src="/logo.png" />
        </Link>
        <MainMenu />
      </div>
      <Card style={{ backgroundColor: '#9feef4', borderColor: 'black', marginTop: 10, padding: 20 }}>
        {/* <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Button style={{ height: 'auto' }} onClick={() => { history.push('/pricing/self-api-key') }}>
            <div style={{ whiteSpace: 'break-spaces', height: 'auto' }}>API</div>
          </Button>
        </div> */}
        <div style={{ marginBottom: 40, marginTop: 60, display: 'flex', flexDirection: 'column' }}>
          <h1 className='pricing-hero-font'>Token Pricing <span style={{ fontSize: 18 }}>GPT-4</span></h1>
          <p style={{ fontSize: 20, lineHeight: 1.4, textAlign: 'center', margin: 'auto', maxWidth: 800, marginBottom: 40 }}>
            Our pricing structure is designed to be flexible and cost-effective, ensuring an optimal fit for your diverse educational needs whether you are an educator developing tailored courses or a student wanting to enhance your learning experience!
          </p>
          <PriceSlider
            decimalInputValue={decimalInputValue}
            setDecimalInputValue={setDecimalInputValue}
            handleGenerateBuyTokenPaymentLink={() => {
              if (localStorage.accessToken) {
                handleGenerateBuyTokenPaymentLink()
              } else {
                history.push('/signin')
              }
            }}
          />
        </div>
      </Card>
      {
        !userEmail &&
        <Card style={{ backgroundColor: '#fff8d3', marginTop: 10, borderColor: 'black', padding: '10px 20px' }}>
          <div style={{ display: 'flex', flexDirection: window.innerWidth > 600 ? 'row' : 'column', gap: 20, justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ textAlign: window.innerWidth > 600 ? 'left' : 'center', fontSize: 17, marginBottom: 0 }}>
              Register now to experience how our AI course builder works with FREE tokens.
            </div>
            <Button onClick={() => { history.push('/signin') }}>
              Register
            </Button>
          </div>
        </Card>
      }
      {
        userEmail &&
        <Card style={{ backgroundColor: '#fff8d3', marginTop: 10, borderColor: 'black', padding: '10px 20px' }}>
          <div style={{ display: 'flex', flexDirection: window.innerWidth > 600 ? 'row' : 'column', gap: 20, justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ textAlign: window.innerWidth > 600 ? 'left' : 'center', fontSize: 17, marginBottom: 0 }}>
              Need a discount for bulk orders? Contact us.
            </div>
            <a href="mailto:resz@chat2course.com?subject=Bulk%20Order%20Request%20for%20Chat2Course%20Tokens&body=I%20am%20interested%20in%20placing%20a%20bulk%20order%20for%20Chat2Course%20tokens.%0D%0A%0D%0AThe%20amount%20of%20tokens%20I%20wish%20to%20order%20is%3A%20%3CInsert%20amount%20here%3E" style={{ alignSelf: 'center' }}>
              <Button>
                Contact us
              </Button>
            </a>
          </div>
        </Card>
      }
      <div style={{ padding: '40px 40px' }}>
        <Markdown content={tokenExplanation} />
      </div>
      <Card style={{ backgroundColor: '#9feef4', borderColor: 'black', padding: 20 }}>
        <h3 style={{ textAlign: 'center', margin: 0, fontFamily: 'Outfit' }}>
          By utilizing tokens for these actions, we ensure that you only pay for the services you need, providing you flexibility and unparalleled control over your course creation and learning processes.
        </h3>
      </Card>
    </div>
  )
}

export default Pricing;
